@import "../../../resources/styles/variables";
@import "../../../resources/styles/media";
@import "../../../resources/styles/colors";

.cardListContainer {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 1.6rem 0;
}

.cardList {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}

.cardList > a {
  display: flex;
  @media (max-width: @screen-sm) {
    flex-direction: column;
  }
}

.loadMoreHeaderInfo {
  color: @secondaryText;
  font-size: 0.875rem;
  margin-left: 0.8rem; // Matches Card CSS, we want this to line up with the first card.
  @media (max-width: @screen-sm) {
    margin-left: 0;
  }
}