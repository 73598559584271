@import "../../../../resources/styles/base-colors.less";
@import "../../../../resources/styles/colors.less";
@import "../../../../resources/styles/media.less";

.branchSelectorAndContent {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.selectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        margin-left: 10px;
    }
}

.childContent {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-around;
    padding: 0.125rem;
    // https://www.w3.org/TR/css-flexbox-1/#min-size-auto
    // This prevents the child from growing larger than the parent flexbox container if the childs content is large
    min-width: 0;
}

// Styles for FilterableDropDownMenu
// TODO: Refactor to its own component

.dropDownMenu {
    display: block;
    background: @subtleButtonBackground;
    border: 1px solid @subtleButtonBorder;
    border-radius: 5px;
}

.warning {
    margin: 0.5rem 1rem;
    color: @alertText;
    font-size: 12/16rem;
    width: 250px;
}

.refreshButton {
    width: 2rem !important;
    height: 2rem !important;
}

.fetchButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: @subtleButtonBackground;
}

.fetchButton {
    border: unset !important;
    width: 100% !important;
    margin-right: unset !important;
    &:hover {
        border: unset !important;
    }
}

.value {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectContainer {
    min-width: 150px;
    @media (max-width: @screen-sm) {
        width: 150px;
    }
}

.dropDownMenu {
    width: 100%; // 100% of our selectContainer
}
