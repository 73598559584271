@import "colors";

html {
    font-size: 100%;
    color: @primaryText;
}

body {
    font-size: 1rem;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    overflow: auto;
    margin: 0;
    padding: 0;
    background: @primaryBackground;
    letter-spacing: 0.25px;
    line-height: 1.25rem;
}

// Links
a {
    text-decoration: none;
}

// Lists
ul,
ol {
    list-style-type: none;
    padding: 0;
}

// Code
code {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    color: @codeText;
    background-color: @codeHighlight;
    border-radius: 2/16rem;
    padding: 2/16rem 4/16rem;
    line-height: normal;
}

pre {
    //background-color: @codeHighlight;
    color: @codeText;
    padding: 0.4rem;
    margin: 0 0 0.4rem;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
}
