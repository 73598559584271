@import "../../../resources/styles/variables";
@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";

.logoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width:100%;
  align-items: center;
  position: relative; // We absolutely position the logo input in here, so this is needed.
}
