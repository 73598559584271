@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.actionCategory {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    padding: 0.5rem;
    background: @paper2;
    box-shadow: @boxshadow1;
    border-radius: 0.25rem;
    height: 6.75rem;
    position: relative; // Needed for .activeIcon
    user-select: none;
    -ms-user-select: none; // IE11
    &:hover {
        background-color: @cardHover;
    }
    svg {
        fill: @iconDark;
    }
}

.isSelectable {
    &:hover, &:focus {
        cursor: pointer;
        box-shadow: @boxshadow3;
    }
}

.blurred {
    opacity: 0.2;
}

.categoryName {
    color: @mainText;
    text-align: center;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 95/16rem;
}

.active {
    border: 0.125rem solid transparent;
    background-color: @mainBackground;
    .categoryName {
        color: @whiteConstant;
    }
    svg {
        fill:  @whiteConstant;
    }
    &:hover {
        background-color: @mainBackground;

    }
}

.inactive {
    border: 0.125rem solid transparent;
}

.activeIcon {
    position: absolute;
    top: -0.65rem;
    right: -0.65rem;
    background: @iconLight;
    border-radius: 1.875rem;
    height: 1.43rem;
    width: 1.43rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        margin-top: 0.0625rem;
        fill: @mainBackground;
    }
}