@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.shadow() {
  box-shadow: @boxshadow3;
}

.container {
  text-align: center;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  .shadow();
  background-color: @whiteConstant;
  border-radius: 0.25rem;
  overflow: hidden; // To support border-radius and our horizontal loading indicator.
  width: 360/16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
      color: @whiteConstant;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0 1.5rem 0;
  }
}

.inProgressMessage {
    text-align: center;
    margin-bottom: 2rem;
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 18.75rem;
      height: 6.976875rem; // Using height to avoid screen jumping on slow-loading connections.
      margin-top: 2rem;
    }
  }

.errorInfoPanel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}

.errorInfoPanelDetails {
    .shadow();
    width: 100%;
    text-align: left;
    background-color: @secondaryBackground;
    padding: 0.5rem;
}
