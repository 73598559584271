@import "../../resources/styles/colors";
@import "../../resources/styles/media";
@import "../../resources/styles/variables";

.topnavbar {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.eapBanner {
    background-color: @alertBackground;
    color: @alertText;
    font-size: 13/16rem;
    height: 24/16rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: @semibold;
    a,
    a:hover {
        color: @alertHeaderText;
    }
}

.closeBanner {
    position: absolute;
    right: 0;
    height: auto;
}

.navbar {
    height: 48/16rem; // This matches our AreaTitle.titleBar height
    background-color: @navBackground;
    display: flex;
    border-bottom: 1px solid @divider;
}

.navbarLeft,
.navbarRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
}

.navbarCenter {
    flex-grow: 1;
    flex-shrink: 1;
    ul {
        height: 100%;
        margin: 0;
        display: flex;
        li {
            display: inline-block;
            height: 100%;
            a {
                .button;
                em {
                    .icon;
                }
            }
        }
    }
}

.navbarRight {
    float: right;
}

.button {
    cursor: pointer;
    height: 100%;
    color: @navText;
    padding: 0 @margin-double;
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover {
        background-color: @navItemHover;
        color: @navTextHover;
    }
    &:active,
    &:focus {
        background-color: @navItemActive;
    }
}

.downArrow {
    color: @whiteConstant;
}

.projectsLink {
    padding: 0 0 0 1rem !important; // Our accessoryView means this projects menu does not need the regular link padding.
}

.projectSwitcherLink {
    border-radius: 0 !important;
    margin: 0 0 0 0.6rem !important;
    padding: 0 0.3rem !important;
    position: relative;
    align-self: stretch;
    &::before {
        content: "a";
        color: transparent;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.3);
        position: relative;
        left: -0.3rem;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
    &:focus {
        background-color: @navItemActive;
    }
}

.projectsLink:hover .projectSwitcherLink {
    border-color: rgba(255, 255, 255, 0.5);
    padding-top: 0;
    padding-bottom: 0;
}

.navItemActive {
    background-color: @navItemActive;
    color: @navTextActive !important;
}

.spaceIcon {
    margin-right: 1rem;
}

.icon {
    font-size: 1.0625rem;
    padding: 0 0.5rem;
}

.userName,
.spaceName {
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: @margin-double;
    padding-left: @margin-single;
    min-width: 3rem;
}

.navbarRight > div {
    height: 100%;
}

.groupedRow {
    display: flex;
    flex-direction: row;
    height: 100%;
}

@media (max-width: @screen-sm) {
    .navbar {
        height: unset;
    }
    .navbarRight {
        margin-right: @margin-single;
        flex-direction: column;
    }
    .navbarLeft {
        margin-left: @margin-single;
        margin-right: @margin-single;
        float: none;
    }
}

.newFeature {
    display: flex;
    align-items: center;
}
