@import "../../../resources/styles/colors";

.actions {
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    flex-wrap: wrap; // Need this to wrap for mobile.
}

.wizardActions {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-wrap: wrap; // Need this to wrap for mobile.
  align-items: flex-start; // To keep the expected horizontal alignment between actions.
}

.primaryActions {
  display: flex;
  flex-direction: row;
  align-items: flex-start; // To keep the expected horizontal alignment between actions.
    > * {
      margin-left: 0.5rem;
    }
}

.secondaryActions {
  display: flex;
  flex-direction: row;
  align-items: flex-start; // To keep the expected horizontal alignment between actions.
    > * {
      margin-right: 0.5rem;
    }
}

.step {
  height: 0.25rem;
  flex: 1 1 auto;
  background-color: @pagingButtonBackground;
  margin-right: 0.25rem;
  cursor: pointer;

  &.active {
    background-color: @mainBackground;
  }
}

.steps {
  :first-child{
    margin-left: 0.25rem;
  }
  display:flex;
  justify-content: center;
  width: 50%;
  align-self:center;
}