@import "../../resources/styles/colors.less";
@import "../../resources/styles/media";

.popoverMenu a {
    color: @whiteConstant;
}

.pagingControlButton {
    cursor: pointer;
    border: 0;
    padding: 0 0.25rem; // Required in the event of long-content.
    margin: 0 0.0625rem;
    min-width: 1.75rem; // min-width required in the event of long-content.
    height: 1.75rem;
    border-radius: 0.125rem;
    background-color: @pagingButtonBackground;
    color: @secondaryText;

    @media (max-width: @screen-sm) { // Make the touch area larger for iPhone/touch situations.
        width: 2rem;
        height: 2rem;
    }

    &:hover, &:focus {
        background-color: @pagingButtonBackgroundHover;
        color: @whiteConstant;
        outline:0;
    }

    &:active {
        background-color: @pagingButtonBackgroundActive;
        color: @secondaryText;
    }

    .pagingControlButtonText {
        width: 0.5rem;
        height: 1.25rem;
        font-family: Roboto;
        font-size: 0.75rem; /* mark.siedle: This differs from the styleguide on purpose ... in double digits 14px looked too crammed */
        line-height: 1.36rem;
        text-align: left;
    }
}

.pagingControlButtonActive {
    background-color:  @pagingButtonBackgroundActive;
    color: @whiteConstant;
}

.pagingControlButtonInactive {
    background-color: @pagingButtonBackground;
    color: @primaryText;
}

.buttonWrapper {
    display: inline-block;
}

.globalActionButton {
    height: 2.25rem; // Otherwise the additional border can force the height to increase.
    font-size: initial !important; /* mark.siedle - Needed to stop MaterialUI setting this as 'inherit' which slightly offsets things from aligning horizontally next to text. */
    //line-height: 2.25rem;
    min-width: 5rem!important;
    white-space: nowrap !important; /* mark.siedle - Needed to stop buttons wrapping when HTML gets squished (ie. Task "Try again" button) */
    align-items: center;
    display: flex;
    svg {
        fill: @secondaryButtonText !important;
        margin-right: 0.25rem;
    }
}
.primaryButton {
    height: 2.25rem; // Otherwise the additional border can force the height to increase.
    background-color: @primaryButtonBackground !important;
    svg {
       fill: @primaryButtonText !important;
     + span {
        padding-left: 0.25rem !important;
      }
    }
}

.secondaryButton {
    line-height: 2.25rem !important;
    background-color: @secondaryButtonBackground !important;
    border: 1px solid @secondaryButtonText !important; // To help this stand out against grey backgrounds.
    box-shadow: none !important;
    svg + span {
      padding-left: 0.25rem !important;
    }
    &:hover {
         border: 1px solid @secondaryButtonText !important;
    }
 }

.ternaryButton {
  background-color: transparent !important;
  color: @ternaryButton !important;
  font-weight: 400 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 3.125rem!important; // Because we strip the padding on these buttons, we also need to lower the min-width for certain links with short text.
  span {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    text-transform: uppercase;
  }
  svg {
    fill: @ternaryButton !important;
    margin-right: 0.5rem;
  }
  svg + span {
    padding-left: 0.25rem !important;
  }
  img + span {
    padding-left: 0.25rem !important;
  }
  &:hover {
    color: @ternaryButtonHover!important;
    svg {
      fill: @ternaryButtonHover !important;
    }
  }
}

.disabled {
    font-weight: 900;
    border: 1px solid @disabledButtonBorder !important;
    height: 2.25rem; // Otherwise the additional border can force the height to increase.
    background-color: @disabledButtonBackground!important;
    color: @disabledButtonText !important;
    svg {
        fill: @disabledButtonText !important;
    }
    &:hover {
        border: 1px solid @disabledButtonBorder !important;
        cursor: not-allowed !important;
        :first-child {
          cursor: not-allowed !important;
        }
    }
}

.ternaryDisabled {
  font-weight: 900;
  height: 2.25rem; // Otherwise the additional border can force the height to increase.
  color: @disabledButtonText !important;
  svg {
      fill: @disabledButtonText !important;
  }
  &:hover {
      cursor: not-allowed !important;
      color: @secondaryText !important;
      :first-child {
        cursor: not-allowed !important;
      }
  }
}