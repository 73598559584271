@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.regardingAnyContainer {
    padding: 0.5rem;
    border: 0.0625rem solid @secondaryBackground;
    border-radius: 0.125rem;
}

.dateRangeFilterContainer {
    width: 16.5rem;
    .dateRangeFilter {
    }
}

.dateFilterNote {
    padding: 0 1rem;
}

.filterHeaderContainer {
    align-items: flex-start;
}

.noResultsContainer {
    width: 20rem;
    margin: 0 auto;
    text-align: center;
}

.dateWrapper {
    font-weight: bold;
    white-space: nowrap;
}

.noWrap {
    white-space: nowrap;
}