@import "../../../../resources/styles/colors";

.typeName{
  color: @primaryText;
  padding: 0;
  margin: 0 0 0.5rem 0;
}

.typeHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.typeHeaderTitleContainer {
  margin: 0 1rem;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.typeBody {
  visibility: visible;
}

.account{
  p {
    margin: 0.5rem 0;
    color: @secondaryText;
  }
}