@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
}

.headingText {
    flex-grow: 1;
}

.resetButton {
    margin: 0 !important;
}

.fullHeightContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto;
    height: 100%;
}

.headingSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; // Need this to wrap for mobile.
}

.filterHeaderContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    @media (max-width: @screen-sm) {
        flex-direction: column;
        align-items: flex-start;
    }

    > div:last-child {
        @media (max-width: @screen-sm) {
            margin-left: unset;
            margin-top: unset;

            > button > div > svg {
                margin-left: unset !important;
            }
        }
    }
}

.additionalHeaderFilter {
    align-self: flex-end;
    padding-right: 0.5rem;
}

.filteringByItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;}

.filteringBy {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
}

.matchInfo {
    margin-left: 0.75rem;
}

.info {
    color: @infoText;
}

.toggleFilters {
    align-self: center;
}