@import "../../../../resources/styles/colors.less";

.taskProgress {
  padding-bottom: 1rem;
}
.disabled {
  font-weight: 900;
  border: 1px solid @disabledButtonBorder !important;
  height: 2.25rem; // Otherwise the additional border can force the height to increase.
  background-color: @disabledButtonBackground!important;
  color: @disabledButtonText !important;
  svg {
      fill: @disabledButtonText !important;
  }
  &:hover {
      border: 1px solid @disabledButtonBorder !important;
      cursor: not-allowed !important;
      :first-child {
        cursor: not-allowed !important;
      }
  }
}