// This should be the only usage of :global in the whole application because styles defined here are.....truly global :)
:global {
  @import 'resources/styles/media';
  @import 'resources/styles/fonts';
  @import 'resources/styles/text';
  @import 'resources/styles/code';
  @import 'resources/styles/colors';
  @import 'resources/styles/base-colors';
  @import 'resources/styles/base';
}
