.container {
    margin: 0.75rem 1rem 0 1rem; // This matches .sidebarLayout
}

.paperContainer {
    margin-top: 0.875rem;
}

.spaceCallout {
    margin-bottom: 0.75rem;
}

.unorderedList {
    list-style-type: disc;
    padding-left: 2rem;
}
.layoutContainer {
    margin-top: 1rem;
}
