@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables.less";

.box {
    padding: 0.625rem 1rem;
    align-self: stretch;
    font-size:14/16rem;
    color: @primaryText;
}

.information {
    background-color: @infoBackground;
    .box;
    .title {
        color: @infoHeaderText;
        .title;
    }
}

.success {
    background-color: @successBackground;
    .box;
    .title {
        color: @successHeaderText;
        .title;
    }
}

.warning {
    background-color: @alertBackground;
    .box;
    .title {
        color: @alertHeaderText;
        .title;
    }
}

.danger {
    background-color: @dangerBackground;
    .box;
    .title {
        color: @dangerHeaderText;
        .title;
    }
}

.newFeature {
    background: @featureBackground;
    .box;
    .title {
        color: @featureText;
        .title;
    }
}

.none {
    .box();
}

.title {
    font-weight: @normal;
    + div {
        margin-top: 0.25rem;
    }
    > .note {
        margin-bottom: 0;
    }
}