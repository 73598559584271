@import "../../resources/styles/colors";

.busyIndicator {
    width: 100%;
}

.busyIndicator div:first-child {
    background-color: @loadingIndicatorBackground;
}

.busyIndicatorHiddenSpacer {
    width: 100%;
    height: 0.25rem; // This matches <LinearProgress> height so we avoid jankiness from show/hide.
}

.circularBusyIndicator div:first-child {
    vertical-align: middle;
    background-color: transparent; // @whiteConstant;
}