@import "../../../../resources/styles/colors.less";

.machineIcon {
    //margin-left: 0.5rem;
    //margin-right: 0.5rem;
    width: 2.875rem;
    height: 3.125rem;
}
.machineImage {
    background-color:@logoBackground;
    border-radius: 3.125rem;
    padding: 0.5rem;
    width: 3.125rem;
    height: 3.125rem;
    justify-content: center;
    align-items: center;
    display: flex;
}