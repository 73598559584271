.container {
    min-height: 62.5rem; // UX: Create empty vertical space to avoid scroll issues when switching between categories.
}

.filterSearchBoxContainer {
    width: 320px; // No larger than 320 for mobile
}

.sectionDivider {
    padding: 8/16rem 18/16rem;
    margin-right: 1rem;
    font-size: 17/16rem;
    border-radius: 0.25rem;
}