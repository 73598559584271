@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";
@import "../../../resources/styles/media";

.list {
    list-style-type: initial;
    padding: inherit;
}

.summaryContainer {
    display: flex;
    flex-direction: column;

    .question {
        size: 0.875rem;
        //color: @ternaryText;
        font-weight: @light;
    }
    .answer {
        size: 0.875rem;
        //color: @ternaryText;
        font-weight: @light;
    }
}