@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";
@import "../../../resources/styles/variables";

.summaryText {
    text-align: left;
    font-weight: @normal;
    margin: 0.25rem 0;
    font-size: 0.875rem;
}

.summarySection {
    margin-top: 0.5rem;
    color: @primaryText;
    font-size: 0.875rem;
}
.summarySectionKey {
    text-align: left;
    font-weight: @bold;
    margin: 0.75rem 0 0.25rem 0;
}
.summarySectionValue {
    text-align: left;
    font-weight: @normal;
    margin: 0.25rem 0;
}

.summaryTable {
    margin-top: 0.5rem;
    padding: 0 1rem;
    color: @primaryText;
    font-size: 0.875rem;
}
.summaryTableKey {
    text-align: left;
    font-weight: @bold;
    width: 25%;
    @media (max-width: @screen-sm) {
        width: unset;
        text-align: initial;
    }
}
.summaryTableValue {
    text-align: left;
    font-weight: @normal;
    @media (max-width: @screen-sm) {
        text-align: initial;
    }
}
.summaryTable table {
    background-color: transparent;
}
.summaryTable tr:hover {
    background-color: transparent !important;
}
.summaryTable tr {
    border: 0 !important;
}
.summaryTable td {
    padding: 0.125rem 0 !important;
}