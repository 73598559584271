@import "../../resources/styles/media";
@import "../../resources/styles/colors";

.buttonDialogContainer {
  display: inline-block;
}

// Hide the MaterialUI dialog items
.materialDialogContent {
  padding: 0;
  max-width: 800px !important;
  @media (max-width: @screen-sm) {
    // Need more width @ low res.
    width:90% !important;
  }
}

.warningIcon {
  color: @alert;
}

.dialogHeader {
  padding: 0!important;
}

.confirmationDialogHeader {
  .dialogHeader();
  background: @alertHighlight;
}