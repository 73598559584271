.openDialogButton {
  min-width: auto !important; // overrides styles from ActionButton
  margin-left: 0.0625rem;
}

.buttonContainer {
  display: inline-flex;
}

.openDialogIcon {
  height: 100% !important; // overrides styles from material UI icons
  margin-left: 0.5rem;
  margin-right: 0.5rem !important; // overrides styles from ActionButton
}