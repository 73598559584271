@import "../../resources/styles/variables";
@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.overlay {
    background-color: @primaryText;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:@zIndexDialogs;
}

.focusArea {
    height: 100%;
    width: 100%;
}