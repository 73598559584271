@import "../../../../resources/styles/text";
@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";

.daysField
{
  margin-left: 4rem;
  max-width: 4rem;
  margin-top: -1rem;
  margin-top: -1rem;
}

.packageVersionCell {
  vertical-align: middle !important;
  &:nth-child(2){
    font-weight: @semibold;

  }
}

.row {
  display: flex;
}

.cardSubTitle {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  color: @primaryText;
  margin-right: 2rem;
  display: block;
  word-wrap: break-word;
  min-width: 50%; // ie and firefox require flex children to have a min-width for word-wrap to work https://stackoverflow.com/questions/36150458/flex-item-overflows-container-due-to-long-word-even-after-using-word-wrap
  @media (max-width: @screen-sm) {
    margin-left: 0rem !important;
  }
  > div > p { // our markdown renderer wraps a div then a p around text which has a bunch of top/bottom margin
    margin: 0;
  }
}

// Task status containers

.box {
  padding: 0.25rem;
  align-self: stretch;
  font-weight: @normal;
  margin-right:0.5rem;
}

.taskSucceeded {
  background-color: @successHighlight;
  .box;
  color: @successText;
  .title {
      color: @successText;
  }
}

.taskFailed {
  background-color: @dangerHighlight;
  .box;
  color: @dangerText;
  .title {
      color: @dangerText;
  }
}

.descriptionColumn {
  min-width: 20rem; // Minimum width to make descriptions readable when there are LONG package names and the table gets squished.
}
.actionButtonGroup {
  margin-bottom: 1rem;
}
.center {
  text-align: center;
}
.packagingHelp {
  //width: 50%;
  margin: 0 auto 2rem auto;
  text-align: center;
}

.packagingHelpTwo {
  width: 70%;
  margin: 0 auto 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > section {
    width: 50%;
    padding: 1rem;
    box-shadow: @boxshadow3;
  }
}

.learnMoreLinks {
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.dropzoneArea {
  margin: 10px 0;
}