@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.healthStatusCardTitleContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    padding: 0;
    margin-left: 1.5rem;
    @media (max-width: @screen-sm) {
        margin-left: 0.75rem;
    }
    .healthStatusIconContainer {
        display: flex;
        min-width: 1rem;
        padding-right: 0.4rem;
    }
    .healthStatusName {
        color: @primaryText;
        font-weight: @normal;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
        text-transform: uppercase;
    }
    .healthStatusMachinesCount {
        color: @secondaryText;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .healthStatusIcon {
        margin-right: 0.25rem;
        background: @logoBackground;
        border-radius: 1.875rem;
        border: 0.0625rem solid @logoBackground;
    }
}