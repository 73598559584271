@import "../../../resources/styles/variables";
@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";
@import "../../../resources/styles/visuallyHiddenButAccessibleStyles.less";

.errorText {
    margin-top: 0.3rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: @dangerText; //rgb(244, 67, 54)
}

.isDefault::after {
    content: " (default)";
    color: @secondaryText !important;
}

.radioButton {
    // mark.siedle - Be careful of adding padding/margin here, as some RadioButtonGroups contain Notes underneath the labels (eg. MachineDiscovery).
    margin-bottom: 0.25rem;
    font-size: 1rem;
}

.radioButtonGroup {
    flex-wrap: wrap; // Needed at low resolutions for horizontal radio button groups.
}

.radioButtonGroup>:not(.radioButton) {
    margin-left: 2rem;
}

.label {
    color: @secondaryText;
    font-size: 0.8125rem;
    font-weight: @normal;
    margin-bottom: 0.25rem;
}

.container {
    width:100%;
    margin-bottom: 1rem; // To make this consistent with our other core component bottom margins.
}

.topMargin {
    margin-top: 1rem;
}

.horizontal {
    display: flex;
    flex-direction: row;
    @media (max-width: @screen-sm) {
        flex-direction: column;
    }
}

.horizontal > div {
    width: auto!important;
    margin-right: 3rem;
    @media (max-width: @screen-sm) {
        margin-right: unset;
    }
}

.visuallyHidden {
    .visuallyHiddenButAccessible();
}