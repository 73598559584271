@import "../../resources/styles/media";
@import "../../resources/styles/colors";
@import "../../resources/styles/variables";


.lookLikeALink {
    color: @mainText;
    cursor: pointer;
    &:hover {
        color: @primaryDark;
    }
}