@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";
@import "../../../../../resources/styles/media";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.newTagContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 0.5rem;

  &>*:not(:first-child):not(:last-child) {
    margin:0 0.5rem;
  }

  &>div:nth-child(1) {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    &>*:nth-child(2) {
      margin:0 0.5rem;
    }
    @media (max-width: @screen-sm) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &>div:nth-child(2) {
    display: flex;
    flex-direction: row;
    width: 40%;
    align-items: center;
    &>*:nth-child(2) {
      margin:0 0.5rem;
    }
    @media (max-width: @screen-sm) {
      width:100%;
      &>*:nth-child(1) {
        width:auto;
      }
    }
  }

  @media (max-width: @screen-sm) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.existingTagcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-top: 0.0625rem solid @divider;
  padding: 0.5rem 0;

  & > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > *:nth-child(2) {
      margin: 0 0.5rem;
    }
    @media (max-width: @screen-sm) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  @media (max-width: @screen-sm) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
  }
}
.description {
  color: @ternaryText;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  max-width: 300px;
  font-weight: @light;
}

.actions {
  margin-left: auto;
}

.deleted {
  text-decoration: line-through;
}

.reorder {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}