@import "../../resources/styles/colors";

.dataGridContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
}

.gridItem {
    margin: 1.25rem;
    &:hover {
        cursor: pointer;
    }
}

.emptyList {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: @secondaryText;
}

.loadMore {
    text-align: center;
}

.pagingControlContainer {
    margin: 1.5rem 1rem;
}

.disablePagingEvents {
    pointer-events: none;
}