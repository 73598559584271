@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";

.emptyList {
    padding: 1.25rem;
}

.headerBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.875rem;
    color: @ternaryText;
    @media (max-width: @screen-sm) {
        flex-direction: column;
    }
}

.actionsMenu {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap; // Need this to wrap for mobile.
}