@import "../../resources/styles/colors.less";
@import "../../resources/styles/variables.less";
@import "../../resources/styles/media.less";

.container {
    display: flex;
    flex-direction: column;
    width: 400/16rem;
}

.menuContainer {
    overflow-y: auto;
    height: 400/16rem;
}


.filterFieldContainer {
    width: 100%;
}

.selected {
    padding: 1rem;
    background-color: @primaryDarkest;
    color: @whiteConstant;
    word-break: break-all;
    font-weight: @semibold;
    display: none;
}

@media (max-width: @screen-sm) {
    .selected {
        display: initial;
    }
}

.rowContainer {
    display: flex;
    align-items: center;
}

.rowLogo {
    padding-right: 7/16rem;
}

.rowTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.emptyButton {
    margin-bottom: 15/16rem;
}

.rowTextTitle {
    word-break: break-all;
}

.rowTextDescription {
    max-height: 40/16rem;
    color: @ternaryText;
    padding-top: 4/16rem;
    word-break: break-all;
    font-size: 0.875rem;
}

.empty {
    padding: 10/16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.description {
    width: 350/16rem;
}

.footer {
    padding: 0.5rem 2rem 0.5rem;
    background-color: @primaryBackground;
    color: @primaryText;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.selectedIndicator {

    > div .rowTextTitle {
        color: @mainText;
        font-weight: @semibold;
    }
}