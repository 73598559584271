@import "../../../../../resources/styles/variables.less";
@import "../../../../../resources/styles/colors.less";
@import "../../../../../resources/styles/media";

.card {
    flex: 1;
    min-width: 16.25rem;
    max-width: 18.75rem;
    @media (max-width: @screen-sm) {
        max-width: 100%;
    }
    .description {
        margin-top: 0.5rem;
    }
    div:nth-child(1) { //needed to make the logo appear. Not sure why the card isn't using the card component logo style.
        width: 100%;
        text-align: center;
        @media (max-width: @screen-sm) {
            width: auto;
        }
        .logo {
            width: auto;
            height: 5rem; // Need a height so the content doesn't jump around as these images load on slow networks.
            margin: 0 auto;
            img {
                height: 5rem;
            }
        }
        div {
            width: auto;
        }
    }
}

.onHoverActions{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: @cardHover;
}