@import "../../resources/styles/variables";
@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.sideMenu {
    padding: 0; // Do not add margins/padding here, or it causes menuitems hover issues.
    width: 16.6rem;
    flex: 0 0 auto;
}

.breakword {
    word-wrap: break-word;
    word-break: break-word;
}

.name {
    .nameHorizontal;
    padding-top: 1.5rem;
    line-height: 1.75rem;
    color: @primaryText;
}

.nameHorizontal {
    .breakword;
    font-size: 1.625rem;
    line-height: 1.5rem;
    min-width: 50%; //required for ie and ff to word-wrap properly
}

.resourceType {
    text-transform: uppercase;
    color: @secondaryText;
    font-size: 0.75rem;
}

.description {
    .breakword;
    padding-top: 1.5rem;
}

.preNav {
    padding: 0.75rem 0;
}

.links {
    padding-top: 0.875rem;
}

.link {
    display: block;
    font-size: 1rem;
    line-height: 2.5rem;
    text-align: left;
    color: @primaryText;
    text-decoration: none;
    padding: 0 1rem; // Important for text/background when looking at hover state.
    &>:active,
    &>:visited {
        color: @primaryText;
    }
}

a.link:hover{
    color: @linkText;
    background: @sideMenuHover; // This is against an already-grey bg, need white.
}

.selected {
    color: @sidebarMenuSelectedText !important;
    font-weight: @semibold;
}

.stackedLayout {
    display: flex;
    flex-direction: column;
}

.title {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
    align-items: center;
}

.logo {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.horizontalLinks {
    height: 2.5rem;
    margin: 0;
    padding-bottom: 1rem;
    ul {
        margin: 0;
        li {
            display: inline-block;
            a {
                .link;
                padding-right: 1rem;
                flex-direction: row;
                display: flex;
                align-content: center;
                align-items: center;
                &:hover {
                    color: @primaryDark;
                    background: @sideMenuHover;
                }
                em {
                    font-size: 1.0625rem;
                    padding: 0 0.5rem;
                }
            }
        }
    }
}

.sidebarLayout {
    display: flex;
    margin-top: 0.875rem;
    margin-right: 1rem;
}

.sidebarLayoutContent {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-around;
    padding: 0.125rem;
    // https://www.w3.org/TR/css-flexbox-1/#min-size-auto
    // This prevents the child from growing larger than the parent flexbox container if the childs content is large
    min-width: 0;
}

.nestedNavLinks {
    padding-bottom: 0.25rem;
    .link {
        line-height: 2rem;
        font-size: 15/16rem;
        color: @sideMenuNestedText;
        padding-left: 1.75rem;
    }
}

.nestedNavLinksParent {
    color: @primaryText !important;
    a.selected {
        color: @primaryText !important;
    }
}

.nestedNavLinksParentSelected {
    font-weight: @semibold;
}
