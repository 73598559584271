@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.container {
    display: flex;
    flex-direction: column;
    width: 500/16rem; // This accommodates exactly 4 recent projects.
    overflow-y: hidden;
    min-height: 500/16rem; // Our VirtualList have a set height of 400. We allow an addition 100 for the search/recent stuff above this.
}

.sectionHeader {
    width: 100%;
    background: @ternaryBackground;
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
}

.recentlyViewedSection {
    width: 100%;
}

.menuContainer {
    overflow-y: auto;
    height: 400/16rem;
}

.filterFieldContainer {
    width: 100%;
}

.filterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.refreshIcon {
    width: 48/16rem !important;
    height: 48/16rem !important;
}

.groupName {
    background-color: @secondaryBackground;
    padding: 8/16rem 18/16rem;
    font-size: 15/16rem;
}

.empty {
    padding: 10/16rem;
}

.warning {
    margin: 0 1rem;
    color: @alertText;
    font-size: 12/16rem;
}

.secondaryText {
    color: @secondaryText !important;
}
