@import "../../resources/styles/colors";

.menuContainer {
    max-height: 400/16rem;
}

.menuItem {
    overflow-wrap: break-word;
}

.empty {
    padding: 10/16rem;
}
.secondaryText {
    color: @secondaryText !important;
}