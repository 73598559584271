@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";

.container {
  margin-top: 1rem; // To give us some space away from the filter box.
}

.actionContainer {
    display: flex;
    justify-content: flex-end;
}

.sectionDivider {
    font-size: 17/16rem;
}

.categoryTemplates > div, .categories > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > * {
        margin-right: 0.5rem !important;
        margin-bottom: 0.5rem !important; // margin at lower resolutions.
    }
}

.categoryTemplates li {
  display: flex;
  height: 100%;
}

@media (max-width: @screen-sm) {
  .templates, .categoryTemplates > div {
    flex-direction: column;
  }
};