@import "../../resources/styles/media";
@import "../../resources/styles/colors";
@import "../../resources/styles/variables";
@import "../../resources/fonts/fontoctopus/fontoctopus";

@completeColor: @successText;

.li-style {
    font-family: FontAwesome;
    display: inline-block;
    padding-right: 5px;
}

.container {
    width: 520/16rem; // No larger or this has issues inside the GettingStartedDialog
    max-width: 600/16rem;
    min-width: 20rem;
    background: @paper1;
    color: @primaryText; // Needed when in dialog.
    display: flex;
    align-items: flex-start; // Don't center, of content jumps around.
    justify-content: center;
    flex-direction: column;
    padding: 0; //1.5rem 0;
    margin: 0;
    .header {
        .title {
            padding: 1rem;
            font-size: 1.063rem;
            font-weight: 500;
        }
        h2 {
            text-align: center;
            width: 100%;
            padding: 0.75rem 0 0;
        }
    }
    >div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-self: stretch;
        width: auto;
        counter-reset: section;
    }
    @media (max-width: @screen-sm) {
        padding: 0;
        width: auto;
    }
}

.liftBusyIndicator {
    div:first-child {
        padding-top: -1.5rem; // Lift up the busy indicator to the top of the dialog (needed for slow connections).
    }
}

.stepContent {
    padding-top: 0.5rem; // Needed to avoid the hit-area of the step buttons.
}

.footer {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: @secondaryBackground;
     div {
        text-align: center;
    }
}

.actionLinkRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem 0;
    .actionLinkRowContent {
        margin-left: 0.5rem;
    }
    svg {
        transform: scale(0.9);
    }
}

.gettingStartedSection {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: auto;
}
.gettingStartedIntro {
    text-align: left;
    padding-left: 4rem;
    margin:1rem 0 0.5rem;
    color: @primaryText;
    font-size: 14px;
    text-indent: -30px;
    &:before {
        font-family: fontoctopus;
        color: white;
        content: '\e800';
        position: relative;
        left: -0.5rem;
        top: 0.7rem;
        padding: 0.5rem;
        background-color: @secondaryText;
        border-radius: 20px;
    }
    &:after {
        content: "\0000a0";
        height: 30px;
        position: relative;
        left: -12.7rem;
        top: 1.2rem;
        border-right: 1px solid @secondaryText;
        color: @whiteConstant;
    }
}

.stepperContainer {
    padding: 0 1rem 1rem 1rem;
    margin-right: 1.75rem; //To make sure the text doesn't go under the button
    /* mark.siedle: hacking MaterialUI, possibly going to hell for this but #YOLO */
    .stepButtonActive {
        div span {
            font-size: 1.125rem!important;
            font-weight: @bold!important;
        }
        /* Targeting the icon this way because iconContainerStyle doesn't work in IE11 */
        div span span {
            transform: scale(1.6);
            margin-left: 2px;
        }
        div span svg {
            color: @successConstant !important;
            fill: @successConstant !important;
        }
        @media (max-width: @screen-sm){
            text-align: left;
        }
    }
    .stepButtonInActive {
        div span {
            font-size: 0.875rem!important;
            font-weight: @normal!important;
        }
        /* Targeting the icon this way because iconContainerStyle doesn't work in IE11 */
        div span span {
            transform: initial;
        }
        div span svg {
            color: @secondaryText!important;
            fill: @secondaryText!important;
        }
        @media (max-width: @screen-sm){
            text-align: left;
        }
    }
    .stepButtonIconComplete {
        div span svg {
            color: @successText !important;
            fill: @successText !important;
            opacity: 0.4;
        }
    }

    .stepperIcon {
        /* mark.siedle: hacking MaterialUI (this is fun) =P */
        transform: scale(0.9);
    }

    /* Lifted from MUI's stepper styles */
    .stepButtonCustomCircleIndex {
        display: block;
        color: rgb(72, 179, 80);
        fill: rgb(72, 179, 80);
        height: 24px;
        width: 24px;
        user-select: none;
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        font-size: 24px;
    }
}
.overviewDialog {
    > div {
width: 800px !important;
    }
}
.section {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: @ternaryText;
    margin: 1rem 0 0.25rem 0;
}
.heading {
    font-size: 1.5rem;
    color: @primaryText;
    font-weight: 400;
    margin-top: 1rem;
}

.content {
    font-size: 0.875rem;
    color: @ternaryText;
    margin: 0.5rem 0;
    ol li {
        list-style-type: decimal;
        margin-left: 1rem;
        font-weight: 600;
        p {
            font-weight: 400;
            font-size: 14px;
            margin-top: 0;
            color: @ternaryText;
          line-height: 1.5em;
        }
    }
}
.overviewImage {
    width: 60%;
    text-align: center;
}
.linkContainer {
    border-top: 1px solid @divider;
    padding: 1rem 0;
    margin: 0.2rem 2rem 0;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: @screen-sm) {
        display: flow-root;
    }
    a {
        @media (max-width: @screen-sm) {
            padding: 0.5rem 0;
        }
    }
}
.withGettingStartedInline {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.justifyLinkwithIcon {
    justify-content: center;
    @media (max-width: @screen-sm) {
        justify-content: left;
    }
    svg {
        transform: scale(0.9);
        margin-right: 0.25rem;
    }
}