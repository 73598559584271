@import "colors";
@import "variables";
@import "media";

h1 {
    font-size: 1.625rem; //26px
    font-weight: @normal;
    color: @primaryText;
    @media (max-width: @screen-sm) {
        font-size: 1.4375rem;
    }
}

h2 {
    font-size: 1.25rem; //20px
    font-weight: @normal;
    color: @primaryText;
    @media (max-width: @screen-sm) {
        font-size: 1.0625rem;
    }
}

h3 {
    font-size: 1.125rem; //18px
    font-weight: @normal;
    color: @primaryText;
    @media (max-width: @screen-sm) {
        font-size: 1.0625rem;
    }
}

h4 {
    font-size: 1rem; //16px
    font-weight: @semibold;
    margin: 1rem 0 0.5rem 0;
    color: @primaryText;
}

h5 {
    font-size: 0.8125rem; //12px
    font-weight: @normal;
    margin: 1rem 0 0.5rem 0;
    color: @primaryText;
}

hr {
    display: block;
    height: 0.0625rem;
    border: 0;
    border-top: solid 0.0625rem @divider;
    margin: 1em 0;
    padding: 0;
}

a {
    color: @linkText;
}

a:hover {
    color: @linkTextHover;
}

p {
    margin: 0.75rem 0;
    //font-size: 14/16rem;
    color: @primaryText;
}

ul {
    color: @primaryText;
}

ol {
    color: @primaryText;
}

// https://stackoverflow.com/questions/2920306/google-chrome-form-autofill-and-its-yellow-background
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px @whiteConstant inset !important;
}

// https://stackoverflow.com/questions/14007655/remove-ie10s-clear-field-x-button-on-certain-inputs
input::-ms-clear {
    width: 0;
    height: 0;
}

small {
    font-size: 14/16rem;
    line-height: 19/16rem;
}

.secondary {
    color: @secondaryText;
}