@import "../../resources/styles/colors";
@import "../../resources/styles/media.less";

.actionList {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap; // Need this to wrap for mobile.
}

.alignStart {
    justify-content: flex-start;
}

.alignEnd {
    justify-content: flex-end;
}

.actionList > :not(:last-child) {
    margin-right: 0.5rem !important;
}
