@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";
@import "../../../resources/styles/media";

.list {
    list-style-type: initial;
    padding: inherit;
}

.centerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.stackedContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.verticalAction {
    margin-top: 0.5rem;
}

.roundedImage {
    border-radius: 1rem;
    background-color: white; //TODO: Special case for now. Will need to consider a dark theme version of the image at some point?
    margin: 1rem;
}
