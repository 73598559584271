@import "../../resources/styles/colors";
@import "../../resources/styles/variables";

.taskDetailsContainer {
    display: flex;
    flex-direction: row;
    min-width: 180px;
    padding: 0.5rem;
    margin: 0.25rem 0;
    justify-content: left;

    .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-left: 0.5rem;
    }

    .description {
        color: @primaryText;
        font-weight: @semibold;
        font-size: 14/16rem;
        text-align:left;
    }

    .date {
        color: @ternaryText;
        font-weight: @normal;
        font-size: 0.6875rem;
        white-space: nowrap; // Important for long datetimes.
    }
}

.stripTopBottomPadding {
    padding: 0 0.5rem;
    margin: 0;
}