@import "../../resources/styles/media";
@import "../../resources/styles/colors";
@import "../../resources/styles/variables";

.drawerOffset {
    right: 18.75rem!important;
}
.drawerOffsetForFooter {
    right: 18rem!important;
}

.gettingStartedFooter {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    height: auto;
    width: auto;
    background: @paper1;
    z-index: 11; // More than sticky, less than CustomDialog.
    box-shadow: @boxshadow2;
    border-radius: 0.25rem;
    padding: 0.25rem; // Needed to offset the border-radius, do not remove pls.
    @media (max-width: @screen-md) {
        margin-left: 1rem;
    }
    .firstSuccessfulDeploymentContainer {
        display: flex;
        justify-content: center;
        flex-direction: row;
        .firstSuccessfulDeployment {
            justify-content: flex-start;
            display: flex;
            flex-direction: row;
            padding: 0 1rem 1rem 1rem;
            max-width: 40rem;
        }
        @media (max-width: @screen-sm) {
            flex-direction: column;
        }
    }
    .gettingStartedFooterActions {
        position: fixed;
        margin: 2/16rem;
        right: 0.25rem; // No more or the gettingStartedContent shows underneath this action.
        bottom:0.25rem; // No more or the gettingStartedContent shows underneath this action.
        z-index: 2;
        .gettingStartedButton {
            color: @whiteConstant;
            min-width: 3rem;
            height: 3rem;
            border-radius: 2rem;
            padding: 3/16rem;
            margin-bottom: 2px; //margin-right: 17px; // Needed because of the IE scroll bar autohide, see http://stackoverflow.com/q/17045132/90882
            display: block;
            text-align: right;
            cursor: pointer;
            background-color: @successConstant;
            justify-content: center;
            align-items: center;
            display: flex;
            box-shadow: 0 0.0625rem 0.25rem 0 @shadow12;
            em {
                font-size: 1.25rem;
            }
            span {
                font-size: 1rem;
                padding: 0 1rem;
                vertical-align: middle;
            }
        }
    }
    .gettingStartedMinimize {
        //height: 29/16rem;
    }
}

.externalLinkContainer {
    margin: 1rem 0;
}

.successImage {
    height: auto;
    width: 300px;
    margin: 0.5rem;
    justify-content: center;
    display: flex;
    align-self: flex-end;
}

.intensePulse {
    animation: intensePulse 2s infinite;
}
@-webkit-keyframes intensePulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 @successConstant;
    }
    70% {
        -webkit-box-shadow: 0 0 0 0.75rem @transparent;
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 @transparent;
    }
}
@keyframes intensePulse {
    0% {
        -moz-box-shadow: 0 0 0 0 @successConstant;
        box-shadow: 0 0 0 0 @successConstant;
    }
    70% {
        -moz-box-shadow: 0 0 0 0.75rem @transparent;
        box-shadow: 0 0 0 0.75rem @transparent;
    }
    100% {
        -moz-box-shadow: 0 0 0 0 @transparent;
        box-shadow: 0 0 0 0 @transparent;
    }
}

.lightPulse {
    animation: lightPulse 2s infinite;
}
@-webkit-keyframes lightPulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 @successConstant;
    }
    70% {
        -webkit-box-shadow: 0 0 0 1rem @transparent;
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 @transparent;
    }
}
@keyframes lightPulse {
    0% {
        -moz-box-shadow: 0 0 0 0 @successConstant;
        box-shadow: 0 0 0 0 @successConstant;
    }
    70% {
        -moz-box-shadow: 0 0 0 0.5rem @transparent;
        box-shadow: 0 0 0 1rem @transparent;
    }
    100% {
        -moz-box-shadow: 0 0 0 0 @transparent;
        box-shadow: 0 0 0 0 @transparent;
    }
}

.gettingStartedLinkWrapper {
    padding: 1rem 0 0.5rem;
}