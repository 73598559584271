@import "../../../resources/styles/variables.less";
@import "../../../resources/styles/colors.less";

.checkboxMargin {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width:100%
}

.title {
  color:@secondaryText;
  font-size: 0.75rem;
  font-weight: @normal;
  margin-bottom: 0.25rem;
}
.checkbox{}

.checkboxNote {
  margin-left: 2rem;
}

.message {

  font-size: 0.75rem;

  hr {
    border-width: 2px;
    margin-bottom-width: 0.25rem;
  }
}