@import "../../../../resources/styles/media.less";

.versionControlledRunbookSettingsSpacing {
  margin-top: 1rem;
}

.branchAndCommitSection {
  margin-top: 1rem;
}

.embolden {
  font-weight: 700;
}

.showAdvancedLink {
  font-size: 0.75rem;
  font-weight: 300;
}

.sectionControlContainer {
  display: flex;
  flex-direction: row;
  flex: auto;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  @media (max-width: @screen-sm) {
      padding-left: unset;
  }
}
