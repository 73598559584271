@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";
@import "../../../resources/styles/variables";

.projectVariablesContainer{
  width:100%
}

.expanderTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.helpText{
  color: @secondaryText;
}

.variableContainer:not(:first-child) {
  border-top: 0.0625rem solid @divider;
}

.cardTitle {
  display: flex;
  align-items: center;
  line-height: 1rem;
  font-size: 0.8125rem !important;
  min-height: 4rem;
  padding: 0rem;
  width: 100%; // To make sure this stretches to the bounds of our container.

  h3 {
    display: inline;
  }

  .projectLogoContainer {
    flex-shrink: 0;
  }

  .projectEnvironmentVariableTitle {
    font-weight: @semibold;
    font-size: 1.0625rem !important;
  }
}

.missingVariableIcon {
  color: @alert;
}

.libraryVariableSetContainer {
  flex-grow: 1;
}

.libraryWarning {
  display: inline;
  left: 0.25rem;
  position: relative;
}

.projectCardWarning {
  font-size: 1.2rem;
  align-self: center;
  padding-left: 0.25rem;
}

.projectName {
  width: 22rem;
  font-weight: @semibold;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: row;
  min-width: 10rem;

  div:nth-child(1) {
    max-width: 20rem;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1 20rem;
  }

  @media (max-width: @screen-md) {
    flex-grow: 1;
    flex-direction: column;
    width: unset;
    height: 2.7em;

    div:nth-child(1) {
      max-width: unset;
    }
  }
}

.projectNameAndWarningIconContainer {
  display: flex;
  flex-direction: row;
}