@import "../../../resources/styles/colors";

.reduceVisibility() {
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
}

.materialUIIcon {
    padding: 0 !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex!important;
    width: auto !important;
    height: auto !important;
    span {
        padding: 0rem !important;
    }
    svg {
        fill: @iconNeutral !important;
        color: @iconNeutral !important;
    }
    .cancelSmall{
        height: 20/16rem !important;
        width: 20/16rem !important;
    }

    &:hover {
        background-color: transparent !important;
    }

    .reduceVisibility();
}
.imageIcon {
    width: 24/16rem;
    height: 24/16rem;

    .reduceVisibility();
}

.fontAwesomeIcon {
    color: @iconNeutral;
    .reduceVisibility();
}