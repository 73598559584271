.certInstructionList {
    margin: 0;
    margin-left: 2rem;
    list-style-type: decimal;
    li {
        padding-bottom: 0.625rem;
        &:last-child {
            padding-bottom: 0;
        }
    }
}

.testingSuccess {
    display: flex;
    span:nth-child(2) {
        margin-left: 0.5rem
    }
}