@import "../../resources/styles/colors.less";

.codeEditorContainer {
    position: relative;
    min-height: 15rem;
    border: 0.0625rem solid @divider;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.codeEditorContainerFullScreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    z-index: 999;
    background: @paper0;
}

.enterFullScreen {
    z-index: 9; // Needs to be less then the sticky components
    background-color: @secondaryBackground;
    border-bottom: 1px solid @divider;
    display: flex;
    justify-content: flex-end;
}

.exitFullScreen {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    background-color: @secondaryBackground;
    border-bottom: 1px solid @divider;
}

.hintHighlight {
    color: @hintHighlight;
    font-weight: bold;
}

:global {
    // these need to not have their names mangled, as codemirror expects specific names
    .CodeMirror-foldmarker {
        color: @primaryText;
        text-shadow: @secondaryText 1px 1px 2px, @secondaryText -1px -1px 2px, @secondaryText 1px -1px 2px, @secondaryText -1px 1px 2px;
        font-family: arial;
        line-height: 0.3;
        cursor: pointer;
    }
    .CodeMirror-foldgutter {
        width: 0.7em;
    }
    .CodeMirror-foldgutter-open,
    .CodeMirror-foldgutter-folded {
        cursor: pointer;
    }
    .CodeMirror-foldgutter-open:after {
        content: "\25BE";
    }
    .CodeMirror-foldgutter-folded:after {
        content: "\25B8";
    }
    .CodeMirror-gutters {
        background-color: @paper1 !important;
    }

    .CodeMirror-linenumber {
        color: @secondaryText !important;
    }
    .CodeMirror {
        height: inherit;
        font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
        font-size: 1rem;
        background: @paper0 !important;
        color: @primaryText !important;
    }

    .CodeMirror-hints {
        z-index: 99999 !important;
        background: @paper2 !important;
        font-family: Consolas, Menlo, Monaco, "Courier New", monospace !important;
    }

    .CodeMirror-hint {
        color: @placeholder !important;
    }

    li.CodeMirror-hint-active {
        background: @hintActive !important;
        color: @placeholderActive !important;
    }
}
