@import "../../resources/styles/variables";
@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.titleBar {
  background-color: @paper1;
  height: 48/16rem; // This matches our NavBar.navbar height
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.0625rem solid @divider;
}

.rowSpaced {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.title {
  flex-grow: 1;
  font-size: 1.25rem;
  h1, h2 {
    font-size: 1.25rem;
    margin: 0;
    padding: 0;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
}

.helpAction {
  margin-left: 0.5rem;
}

.pageTitleLink {
  color: @primaryText;
  text-decoration: none;
  &>:active, &>:visited{
      color: @primaryText;
  }
  &:hover{
      color: @mainText;
  }
}