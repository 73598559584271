@import "../../resources/styles/colors";

.emptyList {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: @secondaryText;
}

.actionsMenu {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap; // Need this to wrap for mobile.
}

.hidden {
    display: none;
}