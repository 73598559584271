@import "../../../resources/styles/colors";

// React-tippy only provides a theme (light/dark/transparent) which can be used to control these colors
// There is no way to gain more granular control of these colors other than to leverage the class names attached to these elements
:global(.tippy-popper .tippy-tooltip) {
    // !important is used here because this css selector is the same used in the react-tippy library to define the base styles
    // and using other means of increasing specificity may be fragile
    background-color: @tooltipBackground !important;
    color: @tooltipText !important;
}

:global(.tippy-popper[x-placement^="right"] .arrow-regular) {
    border-right-color: @tooltipBackground !important;
}

:global(.tippy-popper[x-placement^="left"] .arrow-regular) {
    border-left-color: @tooltipBackground !important;
}

:global(.tippy-popper[x-placement^="top"] .arrow-regular) {
    border-top-color: @tooltipBackground !important;
}

:global(.tippy-popper[x-placement^="bottom"] .arrow-regular) {
    border-bottom-color: @tooltipBackground !important;
}

.tooltipContent
{
    // Tooltip renders markdown, which creates <p> tags against a dark background. This overrides our base styling so you can read the text, yay!
    p {
        color: @tooltipText;
        padding: 0.25rem 0;
        margin: 0;
    }
}
