@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.container {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    @media (max-width: @screen-sm) {
        flex-wrap: wrap;
    }
    width: 100%;
}

.input {
    flex: 1 1 auto;
}

.actions {
    margin-left: 0.5rem;
}