@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";
@import "../../../../resources/styles/variables";

.formSectionBase() {
    width: 100%;
    box-shadow: none!important; //swap the box-shadow of the material-ui control for a border
}

.formSectionBorder() {
    border-radius: 0!important;
    border-style: solid;
    border-width: 0.0625rem 0 0.0625rem 0;
    border-color: @divider;
}
.formExpander {
    background-color: @paper1 !important;
}
.formExpander, .formNonExpander {
    .formSectionBase();
    .formSectionBorder();
    border-bottom: none;

    // mark.siedle - Oh God, these are hidden away deep in material-ui #plsKillMe
    > div:first-child {
        padding: 0!important;
        > div:first-child {
            padding: 0.75rem 1rem!important;
        }
    }
}

.formSection {
    .formSectionBase();
}

.cardTitleContainer {
    display: flex;
    color: @primaryText;
    align-items: top;
    word-wrap: break-word; // mark.siedle - Needed on settings page for long setting keys to wrap properly.
    @media (max-width: @screen-sm) {
        display:block;
    }
}

.cardTitle {
    top: 1rem;
    width: 15rem !important;
    min-width: 15rem !important;
    min-height: 1.5rem !important;
    font-family: Roboto !important;
    font-size: 1rem !important;
    font-weight: @bold !important;
    line-height: 1.5 !important;
    text-align: right !important;
    color: @primaryText !important;
    @media (max-width: @screen-sm) {
        text-align: left !important;
        position: static !important;
    }
}

.cardSubTitle {
    font-family: Roboto !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    text-align: left !important;
    color: @primaryText !important;
    margin-left: 1.6rem !important;
    margin-right: 2rem !important;
    display: block !important;
    flex-grow: 1;
    word-wrap: break-word;
    min-width: 50%; // ie and firefox require flex children to have a min-width for word-wrap to work https://stackoverflow.com/questions/36150458/flex-item-overflows-container-due-to-long-word-even-after-using-word-wrap
    @media (max-width: @screen-sm) {
        margin-left: 0rem !important;
    }
    > div > p { // our markdown renderer wraps a div then a p around text which has a bunch of top/bottom margin
        margin: 0;
    }
}
.cardSubTitleWithOverflowMenu {
    margin-right: 3rem !important;
}

.cardPlaceholderSubTitle {
    &:extend(.cardSubTitle);
    color: @secondaryText !important;
}

.cardDefaultSubTitle{
    &:extend(.cardSubTitle);
}

.cardDefaultSubTitle::after {
    content: " (default)";
    color: @secondaryText !important;
}

.cardMedia {
    margin-left: 17.6rem;
    margin-right: 4rem;
    margin-top:-0.5rem;
    padding-bottom: 1rem;
    max-width:37.5rem;
    @media (max-width: @screen-sm) {
        margin-left: 1.1rem !important;
        margin-right: 1.1rem;
    }
}

.cardMediaFillRight {
    max-width: none;
}

.cardMediaFillAll {
    margin-left: 0;
    margin-right: 0;
}

.cardMedia .container {
    padding-bottom: 0.5rem !important;
}

.cardMediaNonExpander
{
    margin: 0 1.1rem;
    padding: 1rem 0;
}

.expandLink {
    width: 6rem;
    height: 1.19rem;
    font-family: Roboto;
    font-size: 13/16rem;
    line-height: 1.36rem;
    color: @secondaryText;
    margin-right: 1.5rem;
}

.expandLink:hover {
    color: @mainText;
}

.linkContainer {
    width: 100%;
    text-align: right;
    padding: 5/16rem 0;
}

.moreInformation {
    padding: 1rem;
    background: @secondaryBackground;
    font-size: 14/16rem;
}

.hide {
    font-family: Roboto;
    font-size: 0.75rem;
    line-height: 1.83rem;
    text-align: left;
    color: @secondaryText;
    a {
        color: @mainText;
    }
    text-align: right;
}

.sectionHeading {
    width:100%;
    background: @secondaryBackground;
    .formSectionBorder();
    margin: 0 0 -0.0725rem 0;
}

.dialogSectionHeading {
    background: @secondaryBackground;
    margin: 2rem -1rem 1rem;
    border-style: solid;
    border-width: 0.0625rem 0;
    border-color: @divider;
}

.sectionHeadingTitle {
    padding: 0.6rem 1rem;
    font-size: 17/16rem;
}

.overflowMenuActions {
    margin-left: auto;
    align-items: center;
}

.expandCollapse {
    top: 0;
    bottom: 0;
    right: 0.25rem;
    position: absolute;
}

.noScoping {
    font-weight: bold;
}

.markdownContainer {
    padding: 1rem 1rem;
}