.controlWithRefreshContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-end;
    > div:first-child { /* Necessary hack */
        width: 100%;
    }
}
.refreshContainer {
    align-self: flex-end;
}