@import "../../resources/styles/variables";
@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto;
}

.sectionControlContainer {
    display: flex;
    flex-direction: row;
    flex: auto;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    margin-top: 0.8rem;
    @media (max-width: @screen-sm) {
        padding-left: unset;
    }
}
