@import "../../../../../resources/styles/colors";

.environmentList {
  padding: 0!important;
  div > button {
    padding: 16px 24px 16px 16px !important;
    justify-content: space-between !important;
    align-content: center !important;
    display: flex !important;
    flex-direction: row-reverse !important;
  }
}

.phaseDeleteButton {
  margin-right: 1.4rem!important;
  margin-top: 0.5rem!important;
}

.sectionControl {
  margin-top: 1rem;
}

.actionsMenu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; // Need this to wrap for mobile.
}

.anyEnvironment {
  margin-top: 1rem;
  display: block;
  color: @primaryText;
}

.padUnder {
  margin-bottom: 0.25rem;
}

.missingResource {
  color: @dangerText;
}

.strongText {
  font-weight: strong;
}