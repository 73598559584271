@import "../../../../resources/styles/colors.less";

.dragHandlePositioningContainer{
    position: relative;
    width: 0;
    height: 100%;
}

.dragHandleDraggableArea {
    position: absolute;
    top: 0.25rem;
    bottom: 0.25rem;
    left: -0.5rem;
    width: 1rem;
    cursor: col-resize;
    display: flex;
    justify-content: center;
}

.dragHandleIndicator {
    width: 0.0625rem;
}

.dragHandleIndicatorLight {
    background-color: @divider;
}

.dragHandleIndicatorDark {
    background-color: @linkText;
}