@import "../../resources/styles/colors";
@import "../../resources/styles/variables";
@import "../../resources/styles/media";

.cardGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom:100px;
  a > div { // Override the card left margins.
    margin-left: 0!important; //TODO: Can we do this without using important?
    margin-right: 1rem;
  }
}

.card {
  flex: 1;
  min-width: 16.25rem;
  max-width: 18.75rem;
  @media (max-width: @screen-sm) {
      max-width: 100%;
  }
  .description {
      margin-top: 0.5rem;
  }
  div:nth-child(1) { //needed to make the logo appear. Not sure why the card isn't using the card component logo style.
      width: 100%;
      text-align: center;
      @media (max-width: @screen-sm) {
          width: auto;
      }
      .logo {
          width: auto;
          height: 5rem; // Need a height so the content doesn't jump around as these images load on slow networks.
          display: flex;
          align-items: center;
          justify-content: center;
          img {
              height: 5rem;
          }
      }
      div {
          width: auto;
      }
  }
}

.container {
  margin-top: 2rem;
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
}
