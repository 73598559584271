@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/media";

.lastPublishedTooltipContent {
    text-align: left;
}

.lastPublishedTooltipContainer {
    margin-left: 0.5rem;
    @media (max-width: @screen-sm) {
        margin-left: unset;
    }
}

.formTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: @screen-sm) {
        flex-direction: column;
    }
}