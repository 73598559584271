@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.paperLayoutOverride {
    > div:first-child {
        box-shadow: unset;
        margin: 0;
        border-radius: unset;
    }
}

.cardTitleContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    padding: 0 1rem;
    color: @primaryText;
    line-height: 2.25;

    .environmentIcon {
        display: flex;
        padding-right: 0.4rem;
        width:2rem;
        min-width: 1.5rem;
        height:auto;
    }
    .environmentName {
        font-weight: @semibold;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
        white-space: nowrap;
        font-size: 1rem
    }
    .environmentMachinesCount {
        color: @secondaryText;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .environmentSummaryCounts {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto; // This pulls it right.
        margin-right: 3rem;
        @media (max-width: @screen-sm) {
            display: none; // Too many table/padding issues at small res.
        }
        .summaryCount {
            margin: 0.25rem 0;
            padding-right: 0.4rem;
            display: flex;
            align-items: center;
            font-size: 0.8125rem !important; // Done on purpose to match design, this has to match for both the action button and the close button.
            text-transform: uppercase; // To match ternary action so this toggles seamlessly.
        }
    }
    .environmentOverflowActions {
        position: absolute;
        z-index: 1;
        right: 1rem; // Leave enough room for the down-arrow on the right.
        margin: auto;
        width: 3rem;
        height: 2.25rem;
    }
}

.machineIcon {
    width: 2.438rem;
    height: 2.563rem; // Always set height or the screen gets janky as images load.
}

.healthStatusIcon {
    margin-right:0.25rem;
    vertical-align: middle;
    border: 0.0625rem solid @logoBackground;
    background: @logoBackground;
    border-radius: 1.875rem;
}

// SimpleExpander

.formExpander, .formNonExpander {
    width: 100%;
    box-shadow: none!important; //swap the box-shadow of the material-ui control for a border
    border-radius: 0!important;
    border-width: 0!important;
    margin: 0 0 -0.0625rem 0; //so we don't get double borders with consecutive expanders

    // mark.siedle - Oh God, these are hidden away inside the Card from material-ui #plsKillMe
    > div:first-child {
        padding: 0!important;
    }
}

.cardMedia {
    margin: 0;
    padding: 0;
}

.healthStatusCardTitleContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    padding: 0;
    margin-left: 1.5rem;
    @media (max-width: @screen-sm) {
        margin-left: 0.75rem;
    }
    .healthStatusIconContainer {
        display: flex;
        min-width: 1rem;
        padding-right: 0.4rem;
    }
    .healthStatusName {
        color: @primaryText;
        font-weight: @normal;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
        text-transform: uppercase;
    }
    .healthStatusMachinesCount {
        color: @secondaryText;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
}